import { useUrlState } from '@village/tools';
import { Button, List, Text } from '@village/ui';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import * as Styled from './styles';
import { Summary, ProviderNote } from '../components';
import { LabAnalyte } from '../components/lab-analyte';
import { CenteredSpinner } from 'components/centered-spinner';
import { useIndividualTestResult } from 'data/hooks/use-individual-test-result';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { Countly } from 'modules/countly';
import type { TestResultType } from 'types';
import { useTestResultsByFeatureFlag } from 'utils/test-results';

interface Props {
    readonly testResultId: number | string | undefined;
    readonly testResultType: TestResultType | undefined;
}

const TestResultContent: FC<Props> = ({ testResultId, testResultType }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [origin] = useUrlState('origin');
    const ignoreCache = origin === 'notification';
    const noImagingResultSupport = !useTestResultsByFeatureFlag(['labsSendMessage', 'imagingResultsSendMessage']).includes(
        'imagingresult'
    );
    const shouldTitleLabResult = testResultType === 'labresult' && noImagingResultSupport;
    const { testResult, isFetching } = useIndividualTestResult(
        {
            testResultId,
            testResultType,
        },
        ignoreCache
    );
    const { setMessagingFields, resetMessagingState } = useMessaging();

    const composeMessageCallback = useCallback(() => {
        if (!testResult) return;
        resetMessagingState();
        setMessagingFields({
            messageSubject: testResult.messageSubject,
            messageType: testResult.messageTypeKey,
            provider: testResult.provider,
            providerid: testResult.providerid,
            testResult,
        });

        if (testResult.sendMessageCountlyKey) {
            Countly.addEvent({
                key: testResult.sendMessageCountlyKey,
                segmentation: {
                    flow: 'test_results',
                    messagingVersion: 2,
                    source: 'healthrecords_villagemedical',
                },
            });
        }
        Countly.addEvent({
            key: 'composeInitiate',
            segmentation: {
                initiatedSource: `healthrecords_${testResult.type === 'labresult' ? 'Lab' : 'Image'}`,
                source: 'healthrecords_villagemedical',
            },
        });
        navigate(`/compose-message?source=${testResult.type}&redirect-to=${pathname}`);
    }, [navigate, resetMessagingState, setMessagingFields, testResult, pathname]);

    const viewDocuments = useCallback(() => {
        if (testResult) {
            navigate(testResult.documentURL);
        }
    }, [navigate, testResult]);

    useNativeControls({
        ipcEvents: ['showBackButton'],
        source: 'health-records',
        title: '',
    });

    useEffect(() => {
        if (testResult) {
            Countly.addEvent({
                key: testResult.navigateToCountlyKey,
                segmentation: { source: 'messaging' },
            });
        }
    }, [testResult]);

    if (isFetching) {
        return <CenteredSpinner />;
    }

    if (!testResult) {
        return (
            <Styled.SimplePadding>
                <Text type="h3">{shouldTitleLabResult ? 'Lab results' : 'Test results'} not found</Text>
            </Styled.SimplePadding>
        );
    }

    return (
        <Styled.Container>
            <Summary testResult={testResult} />
            <ProviderNote composeMessage={composeMessageCallback} testResult={testResult} />
            <Styled.ResultTitle>RESULTS</Styled.ResultTitle>
            {testResult.labResult && testResult.labResult.results.length > 0 ? (
                <Styled.ListContainer>
                    <List>
                        {testResult.labResult.results.map((labAnalyte) => (
                            <LabAnalyte key={labAnalyte.analyte_id} labAnalyte={labAnalyte} />
                        ))}
                    </List>
                </Styled.ListContainer>
            ) : null}
            {testResult.hasDocument ? (
                <Styled.SidePadding>
                    <Button fullWidth={true} onClick={viewDocuments} size="large" variant="secondary">
                        View Document
                    </Button>
                </Styled.SidePadding>
            ) : null}
        </Styled.Container>
    );
};

export { TestResultContent };
